<template>
  <div>

  </div>
</template>


<script>
   export default {
         name:'Welcome',
         data(){
             return{
                fileList:[
                    {url:"../../assets/img/1.jpg"},
                    {url:'../../assets/img/2.jpg'},
                    {url:'../../assets/img/3.jpg'},
                    {url:'../../assets/img/4.jpg'},
                    {url:'../../assets/img/5.jpg'},
                    {url:'../../assets/img/6.jpg'},
                ]

             }
         },
       methods:{

       },
       created() {

       }
     }
</script>
<style scoped>
  div{
    text-align: center;
  }
  h2{
    margin-top:40px;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }


</style>